import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
import { persistStore } from 'redux-persist';

import Routes from './Routes';
import store from './store';

import 'bulma/css/bulma.css';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/all.css';
import './styles/style.css';


const persistor = persistStore(store, null, () => {
  console.log('Store rehydrated');
});

ReactDOM.render(
  <React.StrictMode>
    <PersistGate
      persistor={persistor}
      loading={
        <React.Fragment>
          <div className="hero is-fullheight is-light is-bold is-large">
            <div className="columns">
              <div className="column is-full has-text-centered">
                Loading....
              </div>
            </div>
            <div className="hero-foot" />
          </div>
        </React.Fragment>
      }
    >
      <Provider store={store}>
        <Routes />
      </Provider>
    </PersistGate>
  </React.StrictMode>,
  document.getElementById('root')
);

