import React from 'react';
import {Link} from 'react-router-dom';
import {compose} from 'recompose';
import swal from 'sweetalert2';
import {withRouter} from 'react-router-dom';

import withLayout from '../../hocs/withLayout.hoc';

class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {phone: ''};
    this.handleChange = this.handleChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  handleChange(event) {
    this.setState({phone: event.target.value});
  }


  handleSearch(event) {
    event.preventDefault();
    const {history} = this.props;
    const {phone} = this.state;
    if (phone.length > 0) {
      history.push(`/customer/${phone}`);
    } else {
      swal.fire({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        type: 'warning', title: 'Il codice è obbligatorio'});
    }
  }
  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handleSearch(event);
    }
  }
  render() {
    return (
      <React.Fragment>
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-6 is-offset-3 has-text-centered">
                  <h4 className="title is-4">Inserisci codice a barre</h4>
                  <div className="field">
                    <div className="control has-icons-left">
                      <input
                        name="phone"
                        className="input is-large is-rounded"
                        type="password"
                        value={this.state.value}
                        onChange={this.handleChange}
                        onKeyPress={this.handleKeyPress}
                        autoComplete="false"
                        autoFocus={true}
                      />
                      <span className="icon is-small is-left">
                        <i className="fas fa-search"/>
                      </span>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-foot">
          <div className="container">
            <div className="columns is-marginless">
              <div className="column is-4">
                <Link to="/" className="button is-large is-fullwidth is-outlined">
                  <span className="icon is-medium">
                    <i className="fas fa-chevron-left"/>
                  </span>
                  <span>Indietro</span>
                </Link>
              </div>
              <div className="column is-4 is-offset-4">
                <button type="button" className="button is-large is-info is-fullwidth"  onClick={this.handleSearch}>
                  <span>Cerca</span>
                  <span className="icon is-medium">
                    <i className="fas fa-chevron-right"/>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

Search.defaultProps = {};
Search.propTypes = {};

export default compose(
  withRouter,
  withLayout({title: 'Cerca cliente'})
)(Search);
