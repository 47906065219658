import React from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Home from './routes/home/Home';
import List from './routes/list/List';
import Search from './routes/search/Search';
import Customer from './routes/customer/Customer';
import history from './history';

function Routes() {
  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/list" component={List} />
          <Route path="/search" component={Search} />
          <Route path="/customer/:phone" component={Customer} />
        </Switch>
      </div>
    </Router>
  );
}

Routes.defaultProps = {};
Routes.propTypes = {};
export default Routes;
