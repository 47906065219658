import React from 'react';
import { connect } from 'react-redux';

import { setStoreName, reset } from '../actions';

/**
 *
 * @param state
 * @returns {{config: ((options: LocalForageOptions) => boolean) | * | {fromESObservable, toESObservable} | PersistConfig | ((options: string) => any) | (() => LocalForageOptions) | AxiosRequestConfig}}
 */
const mapStateToProps = state => ({ config: state.config });

const mapDispatchToProps = { setStoreName, reset };

const withState = connect(
  mapStateToProps,
  mapDispatchToProps
);

/**
 * HOC that adds the user to the props
 * @returns {function(WrappedComponent)}
 */

function withConfig(WrappedComponent) {
  const ComponentWithState = withState(WrappedComponent);

  function WithConfig(props) {
    return <ComponentWithState {...props} />;
  }
  const wrappedComponentName =
    WrappedComponent.displayName || WrappedComponent.name || 'Component';

  WithConfig.displayName = `withConfig(${wrappedComponentName})`;
  return WithConfig;
}

export default withConfig;
