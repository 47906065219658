import React from 'react';
import {compose} from 'recompose';
import {Link, withRouter} from 'react-router-dom';
import axios from 'axios';

import withLayout from '../../hocs/withLayout.hoc';
import { API_URL } from '../../libs/api';

class List extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loading: true};
  }
  async componentDidMount() {
    const { data: customers, ...rest } = await axios.get(`${API_URL}/api/list`);
    console.log('Customers', customers, rest);
    this.setState({ customers, loading: false });
  }
  render() {
    const { loading, customers } = this.state;
    if (loading) {
      return (
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-4 is-offset-4">
                Loading.....
              </div>
            </div>
          </div>
        </div>
      );
    }
    return (
      <React.Fragment>
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column">
                {!customers && <h3 className="title is-3">Spiacente, nessun record trovato</h3>}
                {customers && (
                  <table className="table is-fullwidth has-background-transparent">
                    <thead >
                      <tr>
                        <th>Nome</th>
                        <th>Telefono</th>
                        <th>Città</th>
                        <th>Indirizzo</th>
                      </tr>
                    </thead>
                    <tbody>
                    {/*<tr key={customer.phone} onClick={() => history.push(`/customer/${customer.phone}`)} style={{ cursor: 'pointer'}}>*/}
                    {customers.map(customer => (
                      <tr key={customer.phone}>
                        <td>{customer.name}</td>
                        <td>{customer.phone}</td>
                        <td>{customer.city}</td>
                        <td>{customer.address}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="hero-foot">
          <div className="container">
            <div className="columns is-marginless">
              <div className="column is-4">
                <Link to="/" className="button is-large is-fullwidth is-outlined">
                  <span className="icon is-medium">
                    <i className="fas fa-chevron-left"/>
                  </span>
                  <span>Indietro</span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
List.defaultProps = {};
List.propTypes = {};
export default compose(
  withRouter,
  withLayout({ title: 'Elenco clienti' })
)(List);