import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { compose } from 'recompose';

import withConfig from '../../../hocs/withConfig.hoc';

function StoreButton({ storeName, onClick, config }) {
  return (
    <button
      className={classNames('button is-large is-fullwidth', config.storeName === storeName && 'is-success')}
      onClick={(event) => {
        event.preventDefault();
        if (config.storeName === storeName) {
          onClick(null);
        } else {
          onClick(storeName);
        }
      }}
    >
      {storeName}
    </button>
  );
}
StoreButton.propTypes = {
  storeName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};


StoreButton.defaultProps = {};
StoreButton.propTypes = {};
export default compose(
  withConfig,
)(StoreButton);
