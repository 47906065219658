import configReducer from './configReducer';

/**
 * Combine application reducers
 * @type {Reducer<S>}
 */
const reducers = {
  config: configReducer,
};
export default reducers;
