import React from 'react';
import { Link } from 'react-router-dom';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import withLayout from '../../hocs/withLayout.hoc';
import withConfig from '../../hocs/withConfig.hoc';
import { setStoreName } from '../../actions';
import StoreButton from './components/StoreButton';
import Swal from "sweetalert2";

function Home({ config, dispatch }) {
  function alert() {
    Swal.fire({
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      timer: 3000, type: 'error', title: 'Devi selezionare un negozio'
    })
  }
  return (
    <>
      <div className="hero-body has-text-centered">
        <div className="container">
          <h1 className="title is-1">
            Seleziona negozio
          </h1>
          <div className="columns">
            <div className="column">
              <StoreButton
                storeName="SAN ZENO"
                onClick={storeName => dispatch(setStoreName(storeName))}
              />
            </div>
            <div className="column">
              <StoreButton
                storeName="VERONA"
                onClick={storeName => dispatch(setStoreName(storeName))}
              />
            </div>
            {process.env.NODE_ENV !== 'production' &&
              (
                <div className="column">
                  <StoreButton
                    storeName="TEST"
                    onClick={storeName => dispatch(setStoreName(storeName))}
                  />
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="hero-foot">
        <div className="container">
          <div className="columns">
            <div className="column">
              <Link
                to="/list"
                className="button is-large is-fullwidth is-link"
                disabled={!config.storeName}
                onClick={(event) => {
                  if (!config.storeName) {
                    event.preventDefault();
                    alert();
                  }
                }}
              >
              <span className="icon is-medium">
                <i className="fas fa-table"/>
              </span>
                <span>Elenco clienti</span>
              </Link>
            </div>
            <div className="column">
              <Link
                to="/search"
                className="button is-large is-fullwidth is-link"
                disabled={!config.storeName}
                onClick={(event) => {
                  if (!config.storeName) {
                    event.preventDefault();
                    alert();
                  }
                }}
              >
              <span className="icon is-medium">
                <i className="fas fa-search"/>
              </span>
                <span>Cerca cliente</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Home.defaultProps = {};
Home.propTypes = {
  config: PropTypes.shape({
    storeName: PropTypes.string
  }).isRequired
};
export default compose(
  connect(),
  withLayout({ title: 'Home' }),
  withConfig
)(Home);
