import { createStore } from 'redux';
import { persistCombineReducers } from 'redux-persist';
import localForage from 'localforage';
// import { composeWithDevTools } from 'redux-devtools-extension';
import { devToolsEnhancer } from 'redux-devtools-extension';

import reducers from './reducers';

const config = {
  key: 'primary',
  storage: localForage,
  blacklist: ['session'], // Don't persist session
  debug: true,
};
const reducer = persistCombineReducers(config, reducers);

/**
 * Redux store
 */
const store = createStore(reducer, /* preloadedState, */ devToolsEnhancer());
// const store = createStore(reducer, composeWithDevTools(
//   applyMiddleware(),
//   // other store enhancers if any
// ));
// const store = createStore(
//   reducer,
//   {}, // initial state
//   compose(
//     applyMiddleware(),
//     // If you are using the devToolsExtension, you can add it here also
//     typeof window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ !== 'undefined'
//       ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__()
//       : f => f
//   )
// );

export default store;
