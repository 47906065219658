import { SET_STORE_NAME, RESET } from '../actions';

const INITIAL_CONFIGURATION = {
  storeName: null
};
/**
 * Config reducer
 * @param state
 * @param action
 * @returns {*}
 */
const configReducer = (state = INITIAL_CONFIGURATION, { type, value }) => {
  switch (type) {
    case SET_STORE_NAME:
      return { ...state, storeName: value };
    case RESET:
      return { INITIAL_CONFIGURATION };
    default:
      return state;
  }
};

export default configReducer;
