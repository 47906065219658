import React from 'react';
import {compose} from 'recompose';
import {Link, Redirect, withRouter} from 'react-router-dom';
import Swal from "sweetalert2";
import axios from 'axios';
// import FileSaver from 'file-saver';
import print from 'print-js'

import withLayout from '../../hocs/withLayout.hoc';
import withConfig from '../../hocs/withConfig.hoc';

import { API_URL } from '../../libs/api';

class Customer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {loading: true, value: ''};
    this.handleChange = this.handleChange.bind(this);
    this.handlePrint = this.handlePrint.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }
  async componentDidMount() {
    const { match: { params: { phone } }, history } = this.props;
    try {
      const { data: customer, ...rest } = await axios.post(`${API_URL}/api/search`, { phone });
      console.log('Customer', customer, rest);
      if (!customer) {
        history.push('/search');
        return Swal.fire({
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          type: 'warning',
          title: 'Codice non trovato'
        });
      }
      this.setState({ customer });
    } catch (error) {
      Swal.fire(
        'Errore durante ricerca',
        JSON.stringify(error),
        'error'
      )
    }
    this.setState({ loading: false });
    await this.addVisit(phone);
  }
  handleChange(event) {
    this.setState({value: event.target.value});
  }
  handleKeyPress(event) {
    if (event.key === 'Enter') {
      this.handlePrint(event);
    }
  }
  async addVisit(phone) {
    const { config: { storeName }} = this.props;
    try {
      const { data: visit, ...rest } = await axios.post(`${API_URL}/api/add-visit`, { phone, place: storeName });
      console.log('Added visit', visit, rest);
    } catch (error) {
      Swal.fire(
        'Errore durante salvataggio',
        JSON.stringify(error),
        'error'
      )
    }
  }

  async handlePrint(event) {
    console.log('Print');
    event.preventDefault();
    const {value, customer } = this.state;

    this.setState({ loading: true });

    const isIE11 = !!(window.navigator && window.navigator.msSaveOrOpenBlob);
    const printPDF = blob => {
       // try {
         isIE11
           ? window.navigator.msSaveOrOpenBlob(blob, 'documents.pdf')
           : print(URL.createObjectURL(blob)); // http://printjs.crabbly.com/
       // } catch (e) {
       //   throw PDFError;
       // }
    };



    try {
      const { data, ...rest } = await axios({
        method:'post',
        url:`${API_URL}/api/discount`,
        responseType:'blob',
        data: { ...customer, quote: value }
      });
      console.log('Data', data, rest);
      const blob = new Blob([data], {type: "application/pdf"});
      const fileURL = URL.createObjectURL(blob);
      //Open the URL on new Window
      // window.open(fileURL);
      printPDF(blob)



      // const timestamp = new Date().getTime().toString();
      // FileSaver.saveAs(blob, `buono-${timestamp}.pdf`);
    } catch (error) {
      Swal.fire(
        'Errore durante generazione buono',
        JSON.stringify(error),
        'error'
      )
    }
    this.setState({ loading: false });

  }
  render() {
    const { loading, customer } = this.state;
    if (loading) {
      return (
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-4 is-offset-4">
                Loading.....
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (!customer) {
      return (
        <Redirect to="/search" />
      );
    }
    return (
      <React.Fragment>
        <div className="hero-body">
          <div className="container">
            <div className="columns">
              <div className="column is-6 is-offset-3 has-text-centered">
                <h4 className="title is-4">Inserisci importo</h4>
                <div className="field">
                  <div className="control has-icons-left">
                    <input
                      name="importo"
                      className="input is-large is-rounded"
                      type="number"
                      value={this.state.value}
                      onChange={this.handleChange}
                      onKeyPress={this.handleKeyPress}
                      autoComplete="false"
                      autoFocus={true}
                    />
                    <span className="icon is-small is-left">
                      <i className="fas fa-euro-sign"/>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <table className="table has-background-transparent is-fullwidth">
                  <tbody>
                    <tr>
                      <td>
                        <strong>
                          Nome
                        </strong>
                      </td>
                      <td>{customer.name || '-'}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          Indirizzo
                        </strong>
                      </td>
                      <td>{customer.address || '-'}, {customer.zip || '-'}, {customer.city || '-'}</td>
                    </tr>
                    <tr>
                      <td>
                        <strong>
                          Note
                        </strong>
                      </td>
                      <td>{customer.notes || '-'}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div className="hero-foot">
          <div className="container">
            <div className="columns is-marginless">
              <div className="column is-4">
                <Link to="/search" className="button is-large is-fullwidth">
                  <span className="icon is-medium">
                    <i className="fas fa-chevron-left"/>
                  </span>
                  <span>Indietro</span>
                </Link>
              </div>
              <div className="column is-4 is-offset-4">
                <button type="button" onClick={this.handlePrint} className="button is-large is-fullwidth is-link">
                  <span className="icon is-medium">
                    <i className="fas fa-file-pdf"/>
                  </span>
                  <span>Preventivo</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
Customer.defaultProps = {};
Customer.propTypes = {};
export default compose(
  withRouter,
  withLayout({ title: 'Cliente' }),
  withConfig
)(Customer);