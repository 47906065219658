/*
 * action types
 */
export const SET_STORE_NAME = 'SET_STORE_NAME';
export const RESET = 'RESET';

export function setStoreName(value = null) {
  return { type: SET_STORE_NAME, value };
}
export function reset() {
  return { type: RESET };
}